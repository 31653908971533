import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Kpl, Poverty } from 'src/app/model/activity';
import { LpService } from 'src/app/service/lp.service';
import { AddPovertyComponent } from '../add-poverty/add-poverty.component';
import { GraphPovertyComponent } from '../graph-poverty/graph-poverty.component';



@Component({
  selector: 'app-view-poverty',
  templateUrl: './view-poverty.component.html',
  styleUrls: ['./view-poverty.component.css']
})
export class ViewPovertyComponent implements OnInit {
  poverty: Poverty[];
  searchVal: any;
  public data = [
  ];
  constructor(private _lpService: LpService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.view();
  }
  view(){
    this._lpService.getPoverty().subscribe((data: any[]) => {
      this.poverty = data;
console.log(this.poverty);

    });
  }
  addPoverty() {
    const dialogRef = this.dialog.open(AddPovertyComponent,{
      width: '75%',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  printPage() {
    window.print()
  }

  viewgraph(){
    this.view()
    const dialogRef = this.dialog.open(GraphPovertyComponent,{
      width: '75%',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }



  
  checkSearchVal() {
    

    let filteredUsers: Poverty[] = [];
    console.log(this.searchVal);
    if (this.searchVal && this.searchVal != '') {
      for (let selectedUser of this.poverty) {
        if (selectedUser.no.toLowerCase().search(this.searchVal.toLowerCase()) != -1 ||
          selectedUser.name.toLowerCase().search(this.searchVal.toLowerCase()) != -1) {
          filteredUsers.push(selectedUser);
        }
      }

      this.poverty = filteredUsers.slice();
    }
  }
 
  




  // tableSearch(){
  //   let input, filter, table, tr, td, txtValue;
  
  //   input =document.getElementById('searchBar');
  //   filter = input.value.toUpperCase();
  //   table = document.getElementById("myTable");
  //   tr = table.getElementsByTagName("tr");
  
  
  
  //   for (let i=0; i<tr.length; i++){
  //     td = tr[i].getgetElementsByTagName("td")[4];
  //     if (td){
  //       txtValue=td.textContent || td.innerText;
  //       if (txtValue.toUpperCase().indexOf(filter)> -1){
  //         tr[i].style.display ="";
  
  //       }else{
  //         tr[i].style.display =  "none";
  //       }
  //     }
  //   }
  // }



  

 
}


