import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LpService } from 'src/app/service/lp.service';
import { first } from 'rxjs/operators';

// import { Component, OnInit } from '@angular/core';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { CandidateService } from '../../services/candidate.service';
// import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  invalidLogin: boolean = false;
  message: any;
  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private apiService: LpService) { }
   ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required])],
      password: ['', Validators.required]
    });
   }

   onSubmit(){
     console.log(this.loginForm.value);
     if (this.loginForm.invalid) {
      return;
    }

     const loginData = {
      username: this.loginForm.controls.username.value,
      password: this.loginForm.controls.password.value
    };

    //  this.apiService.login(loginData).subscribe((data: any) => {
    //   console.log("HI");
      console.log(loginData.username);
      console.log(loginData.password);
      if(loginData.username =="kkmlp" && loginData.password == "12341234"){
        console.log("success");
        this.router.navigate(['dash']);
      }else{
        alert("username or password incorrect")
      }
    //   // this.apiService.login(this.loginForm.value).subscribe((data: any) => {
    //     console.log(data);
    //   this.message = data.message;
    //  console.log(data.token);
    //   if(data.token) {
    //     console.log("Hi")
    //       window.localStorage.setItem('token', data.token);
    //       this.router.navigate(['dash']);
    //    } else {
    //      this.invalidLogin = true;
    //     // alert('a' + data.message);
    //    }
    //  });
   }

  }